import React from 'react';

const SharedContext = React.createContext(
    {
        flowStage: "auth", 
        requestInProgress: false, 
        group_id: null,
        privateContent: null,
        token: null,
        tokenExpiration: null,
        changePage: () => {}
    });
export default SharedContext;
