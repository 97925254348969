
class IdentityToken {
    constructor(data) {
        this.id = data['IdentityId'];
        this.logins = data['Logins'];
        this.token = data['Token'];
        this.expiration = data['tokenExpiration'];
        this.group_id = data['group_id'];
    }

    isExpired() {
        return Date.now() > this.expiration;
    }
}

class Credentials {
    constructor(data) {
        this.accessKeyId = data.Credentials.AccessKeyId;
        this.expiration = data.Credentials.Expiration;
        this.secretKey = data.Credentials.SecretKey;
        this.sessionToken = data.Credentials.SessionToken;
    }

    isExpired() {
        return (Date.now()  > Date.parse(this.expiration));
    }
}

export {IdentityToken, Credentials};