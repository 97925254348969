import React, {useContext} from 'react';
import { Container, 
        Row, 
        Col, 
        InputGroup,
        Button,
        ButtonToolbar,
        Form,
        Spinner
} from 'react-bootstrap'; 
import './AuthMenu.css';
import SharedContext from '../../SharedContext';


function AuthMenu(props) {
    const sharedContext = useContext(SharedContext);
    const [groupIDEntry, updateGroupIDEntry] = React.useState("");
    const [ groupIDIsInvalid, setgroupIDIsInvalid ] = React.useState(false);
    const onGroupIDChange = (e) => {
        updateGroupIDEntry(e.target.value);
    }
    const onGroupIDSubmit = async (e) => {
        e.preventDefault();
        disableSearchButton = true;
        try {
            await props.submitGroupID(groupIDEntry);
        } catch {
            setgroupIDIsInvalid(true);
            setTimeout(setgroupIDIsInvalid, 3000, false);
        }
        disableSearchButton = false;
    }
    const onKeyDown = (e) => {
        setgroupIDIsInvalid(false);
        if (e.keyCode === 13) {
            if (e.preventDefault) e.preventDefault();
            onGroupIDSubmit(e);
        }
    }
    let spinnerToggle = sharedContext.requestInProgress === true ? "showSpinner" : "hideSpinner";
    let searchGlassToggle = sharedContext.requestInProgress === true ? "hideglass" : "showglass"; 
    let disableSearchButton = sharedContext.requestInProgress === true ? true : false;

    return (
        <Container fluid>
            <Row style={{height: "100vh"}}>
                <Col className="auth-main-container">
                    <div className="overlay-div">
                        <img 
                            alt="DestinyandEvan" 
                            data-src={require("../AuthAssets/DestinyandEvanB&W.png")}
                            data-image={require("../AuthAssets/DestinyandEvanB&W.png")}
                            data-image-dimensions="4096x2731" 
                            data-image-focal-point="0.5,0.5" 
                            data-load="false" 
                            data-parent-ratio="0.9" 
                            className="overlay-image" 
                            data-image-resolution="2500w" 
                            src={require("../AuthAssets/DestinyandEvanB&W.png")}
                        >
                        </img>
                        <div className="overlay"/>
                        <div className="overlay-text">
                            <div className="horizontal-text-placement">
                                <h3 className="login-lux-font"><i>Evan &amp; Destiny's</i></h3>
                                <p className="no-margin add-margin">WEDDING WEBSITE</p>
                                <br/>
                                <p className="no-margin add-margin"><i>Please sign in for celebration details</i></p>
                                <hr/>
                                <ButtonToolbar className="signinBox">
                                    <Form.Group role="group">
                                        <InputGroup className="input">
                                            <Form.Control 
                                                className="signinInput"
                                                type="search" 
                                                placeholder="Sign in with GroupID" 
                                                onChange={onGroupIDChange} 
                                                onKeyDown={onKeyDown}
                                                isInvalid={groupIDIsInvalid}
                                            />
                                            <InputGroup.Append>
                                                <Button 
                                                    className="SearchButton" 
                                                    variant="outline-secondary" 
                                                    onClick={onGroupIDSubmit}
                                                    disabled={disableSearchButton}
                                                >                        
                                                    <Spinner
                                                        className={spinnerToggle}
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        disabled={true}
                                                    >
                                                    <span className="sr-only">Searching...</span>
                                                    </Spinner>
                                                    <span className={searchGlassToggle}>&#9906;</span>
                                                </Button>
                                            </InputGroup.Append>
                                            <Form.Control.Feedback type="invalid">Invalid GroupID</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </ButtonToolbar>
                                <hr/>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AuthMenu;