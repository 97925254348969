import React, { Component, Suspense } from 'react';
import './App.css';
// import Header from './Website/Header/Header';
import AuthMenu from './Auth/AuthMenu/AuthMenu';
import { Container } from 'react-bootstrap';
import SharedContext from './SharedContext';
import GuestAPI from './API/GuestAPI';
import AuthManager from './API/AuthManager';
import OperationManager from './API/OperationManager';

const PageContainer = React.lazy(() => import('./Website/PageContainer/PageContainer'));
const Header = React.lazy(() => import('./Website/Header/Header'));
const MobileNavBar = React.lazy(() => import('./Website/MobileNavBar/MobileNavBar'));


class App extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {  
            // user: null, 
            customState: null, 
            // userProfile: null , 
            flowStage: "auth",
            formKey: randomKey(),
            requestInProgress: false,
            formStage: 0,
            toggled: false,
            currentPage: null,
            privateContent: null,
            group_id: null,
        };
        this.resetFormHard = this.resetFormHard.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.changeFormStage = this.changeFormStage.bind(this);
        this.updateInProgressState = this.updateInProgressState.bind(this);
        this.changePage = this.changePage.bind(this);
        this.authDidComplete = this.authDidComplete.bind(this);
        OperationManager.requestInProgressHandler = this.updateInProgressState
        this.api = GuestAPI;
        console.log(`BuildINFO: ${process.env.REACT_APP_BUILDINFO}`)
    }
    handleToggle() {
        this.setState({ toggled: !this.state.toggled })
    } 
    updateInProgressState(newInProgressState) {
        this.setState({requestInProgress: newInProgressState});
    }
    componentDidMount() {
        AuthManager.start(this);
        /// Attempt to login from query params if no other login process (eg from refreshing cached credentials) is in progress. 
        // This may need to check more or different things than are checked below.
        if (this.state.flowStage === 'auth' && this.state.requestInProgress === false) {
            const queryParameters = new URLSearchParams(window.location.search);
            const id_param = queryParameters.get("id");
            if (id_param) {
                //console.log(id_param);
                AuthManager.refreshIDPToken(id_param);
            }
        }
    }

    resetFormHard() {
        console.log('resetting form...');
        const stage = this.state.user !== null ? "form" : "auth";
        this.setState({formKey: randomKey(), flowStage: stage});
    }
    authDidComplete() {
        if (this.state.privateContent !== null) {
            this.setState({ flowStage: "form"});
            return;
        }
        //TODO:- cache this result and don't bother fetching if it exists
        const promise = this.api.fetchPrivateContent().then((json)=> {this.setState({privateContent:json})})
        this.setState({ flowStage: "form", privateContent:promise});
    }

    changeFormStage(stage){
        if (stage === "auth" && this.state.stage !== "auth") {
            this.setState({formStage: stage, formKey: randomKey()});
        } else {
            this.setState({formStage: stage});
        }
    }
    changePage(page) {
        this.setState({currentPage: page})
    }

    render() {
        const flowStageChooser = (flowStage) => {
            switch(flowStage) {
                case "auth":
                    return (<AuthMenu submitGroupID={AuthManager.refreshIDPToken} />);
                    // break;
                case "form":
                    return (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Container className="container">
                                <Header 
                                    changePage={this.changePage}
                                    currentPage={this.state.currentPage}
                                    isSignedIn={['form', 'submitted'].includes(this.state.flowStage)} 
                                    resetForm={this.resetFormHard} 
                                    formStage={this.state.formStage}
                                    changeFormStage={this.changeFormStage}
                                    toggled={this.state.toggled}
                                    handleToggle={this.handleToggle}
                                />
                                {/* div for mobile hiding/rendering */}
                                <div className={pageClass}>
                                    <PageContainer
                                    changePage={this.changePage}
                                    currentPage={this.state.currentPage}
                                    key={this.state.formKey} 
                                    userProfile={this.state.userProfile} 
                                    resetForm={this.resetFormHard} 
                                    formStage={this.state.formStage}
                                    changeFormStage={this.changeFormStage}
                                    api={this.api}
                                    handleToggle={this.state.handleToggle}
                                    />
                                </div>
                                <Suspense fallback={<div>Loading Navigation...</div>}>
                                <MobileNavBar 
                                    changePage={this.changePage}
                                    currentPage={this.state.currentPage}
                                    toggled={this.state.toggled}
                                    handleToggle={this.handleToggle}
                                />
                                </Suspense>
                            </Container>
                        </Suspense>
                    )
                default:
                    return ('unknown stage');
            }
        }
        let pageClass = this.state.toggled === false ? 'pageContents' : 'pageContentsHidden';

        return (
            <SharedContext.Provider value={{flowStage:this.state.flowStage, requestInProgress:this.state.requestInProgress, privateContent: this.state.privateContent, changePage: this.changePage}} >
                {flowStageChooser(this.state.flowStage)}
            </SharedContext.Provider>
        );
    }
}

export default App;


const randomKey = () => String(Math.floor(Math.random() * 100000));
