const awsConfig = {
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_YK6mkvrua',
        userPoolWebClientId: '7d44737kr7gjghpbv0jo86c59s',
        aws_user_pools_id: 'us-east-1_YK6mkvrua',
        aws_user_pools_web_client_id: "7d44737kr7gjghpbv0jo86c59s",
        oauth: {
            domain: 'auth.evansdestiny.com',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: window.location.origin.toString(),
            redirectSignOut: process.env.REACT_APP_BASE_URL,
            responseType: 'code' 
        },
        federationTarget: "COGNITO_USER_POOLS"
    },
    API: {
        endpoints: [
            {
                name: "evansdestinyAPI",
                endpoint: process.env.REACT_APP_API_URL
            }
        ]
    }
}


export {awsConfig as default};
