///AuthManager
import Amplify, { Auth, Hub } from 'aws-amplify';
import AWSConfig from './AWSConfig';
import OperationManager from './OperationManager';
// import sigV4Client from './sigV4client';
import GuestAPI from './GuestAPI';
import {IdentityToken, Credentials} from './TokenContainers';
import AWS from 'aws-sdk';


let creds = new AWS.CognitoIdentityCredentials({IdentityPoolId:process.env.REACT_APP_IDENTITY_POOL_ARN});
let cognitoIdentity = new AWS.CognitoIdentity({region:'us-east-1', credentials: creds});
Amplify.configure(AWSConfig);

class AuthManager { 
    constructor() {
        this.group_id = null;
        this.userProfile = null;
        this.userToken = null;
        this.identityToken = null;  
        this.start = this.start.bind(this);
        this.signOut = this.signOut.bind(this);
        this.onUserPoolSignIn = this.onUserPoolSignIn.bind(this);
        this.refreshIDPToken = this.refreshIDPToken.bind(this);
        this.refreshCredentials = this.refreshCredentials.bind(this);
        this.getTokenWithRefresh = this.getTokenWithRefresh.bind(this);
        this.getCredentials = this.getCredentials.bind(this);
        this.authDidComplete = this.authDidComplete.bind(this);
    }

    start(app) {
        this.app = app;
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    this.onUserPoolSignIn(data);
                    break;
                case "signOut":
                    this.signOut();
                    break;
                case "customOAuthState":
                    this.setState({ customState: data });
                    break;
                default: break;
            }
        });

        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((user) => {
                this.onUserPoolSignIn(user);
            })
            .catch(() => {
                console.log("Not signed in");
                this.refreshIDPToken().catch((e)=>{});
            });
    }

    async signOut() {
        //Cache.removeItem("private");
        this.group_id = null;
        this.userProfile = null;
        this.userToken = null;
        this.identityToken = null;  
        //below should clear out all user info and return the browser to a clean login screen.
        this.app.user = null;
        //also reset URL query id if one was used
        window.history.replaceState(null, "", "/");
        window.location.reload(); //hacky solution to stickyness of logged in state.
        //this.app.resetFormHard();

        console.log("user signed out")
    }

    async onUserPoolSignIn(user) {
        if (user["signInUserSession"] === undefined) {
            console.log("onSignIn called without session");
            return;
        }
        const getUserDataFromToken = (user) => {
            const { given_name, name, family_name, email } = user.signInUserSession?.idToken?.payload ?? {};
            return { given_name, name, family_name, email };
        }
        const userProfile = getUserDataFromToken(user);
        this.userToken = user;
        this.userProfile = userProfile;
        
        //console.log(`setting userProfile: ${JSON.stringify(userProfile)}`);
        this.refreshIDPToken().catch((e)=>{});
    }

    ///handles setting and refreshing of idpToken, sets group_id with provided if it yields a token
    async refreshIDPToken(new_group_id) {
        let userToken = await Auth.currentSession().then((session) => {
            return session?.getIdToken()?.getJwtToken();
        }).catch(() => {return null;});
        let existingGroupID = new_group_id ?? this.group_id;
        if (existingGroupID !== null) {
            existingGroupID = existingGroupID.toLowerCase();
        }
        if (userToken == null && existingGroupID == null) {
            //console.log("No user or groupID found");
            throw new Error("No user or groupID found");
        }
        console.log(`Aquiring access token with userToken: ${userToken} , group_id: ${existingGroupID}`);
        try {
            const identityResult = await GuestAPI.getIDPToken(userToken , existingGroupID);
            console.log(`identity result: ${identityResult}`);
            this.group_id = identityResult.group_id;
            this.identityToken = new IdentityToken(identityResult);
            this.refreshCredentials();
            return this.identityToken;
        } catch (error) {
            //console.log(`error fetching idp token: ${error}`);
            this.identityToken = null;
            this.group_id = null;
            this.app.changeFormStage("auth");
            throw new Error('login failed');
        }
    }

    // returns a token immediately if one exists and is not expired. Otherwise attempts to refresh the token.
    async getTokenWithRefresh() {
        if (this.identityToken !== null) {
            return this.identityToken;
        }
        return await this.refreshIDPToken()
    }

    async refreshCredentials() {
        if (!OperationManager.addOperation("getCredentials")) {
            return false;
        };
        if (this.identityToken === null || this.identityToken.isExpired()) {
            await this.refreshIDPToken();
        }
        let params = {
            IdentityId: this.identityToken.id,
            Logins: {'cognito-identity.amazonaws.com': this.identityToken.token}
        }
        let credResponse = await cognitoIdentity.getCredentialsForIdentity(params).promise();
        this.credentials = new Credentials(credResponse);
        OperationManager.removeOperation("getCredentials");
        //console.log(JSON.stringify(credResponse));
        //console.log(JSON.stringify(this.credentials));
        this.authDidComplete();
        return this.credentials;
    }
    ///Check if credentials are valid, if not, refresh.
    async getCredentials() {
        if (this.credentials === null || Date.now() > this.credentials.expiration) {
            return this.refreshCredentials();
        } else {
            return this.credentials;
        }
    }

    async authDidComplete() {
        this.app.authDidComplete();
    }

}



const _manager = new AuthManager();
export default _manager;


/*
formKey = randomKey()
flowStage: "auth"
 */