import { API, Auth } from 'aws-amplify';
import AuthManager from './AuthManager';
import OperationManager from './OperationManager'
import { AwsClient } from 'aws4fetch'

class GuestAPI {

    async sig4Client() {
        let creds = await AuthManager.getCredentials();
        return new AwsClient({
            accessKeyId: creds.accessKeyId,     // required, akin to AWS_ACCESS_KEY_ID
            secretAccessKey: creds.secretKey, // required, akin to AWS_SECRET_ACCESS_KEY
            sessionToken: creds.sessionToken,    // akin to AWS_SESSION_TOKEN if using temp credentials
            service: "execute-api",         // AWS service, by default parsed at fetch time
            region: 'us-east-1',          // AWS region, by default parsed at fetch time
            // cache,           // credential cache, defaults to `new Map()`
            retries: 2,         // number of retries before giving up, defaults to 10, set to 0 for no retrying
            // initRetryMs,     // defaults to 50 – timeout doubles each retry
          })
    }

    searchGuests = async (group_id) => {
        OperationManager.addOperation("searchGuests");
        let result;
        let userToken = await Auth.currentSession().then((session) => {
            return session?.getIdToken()?.getJwtToken();
        }).catch(() => {return null;});
        let url = process.env.REACT_APP_API_URL + '/guests';
        if (userToken !== null) { 
            url = url + `?userToken=${userToken}`;
        }
        try {
            if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_USE_REMOTE === "1") {
                console.log("remote search");
                let sig4 = await this.sig4Client();
                let response = await sig4.fetch(url, {method:'GET', headers:{}, body: undefined});
                result = await response.json();
            } else {
                console.log("local search: ");
                console.log(process.env)
                result = await this.localMockSearch(url);
            }
            OperationManager.removeOperation("searchGuests");
        } catch (err) {
            OperationManager.removeOperation("searchGuests");
            throw err;
        }
        return result;
    }

    submitGuestUpdates = async (guests, deletedPlusOnes) => {
        OperationManager.addOperation("submitGuests");
        let result;
        for (let i in guests) {
            if (guests[i].guest_id.includes(":G")) {
                guests[i].guestOf = guests[i].guest_id.split(":G")[0]
            }
        }
        let body = JSON.stringify({ guests, deletedPlusOnes });
        let userToken = await Auth.currentSession().then((session) => {
            return session?.getIdToken()?.getJwtToken();
        }).catch(() => {return null;});
        let url = process.env.REACT_APP_API_URL + '/guests';
        if (userToken !== null) { 
            url = url + `?userToken=${userToken}`;
        }
        try {
            if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_USE_REMOTE === "1") {
                let sig4 = await this.sig4Client();
                let response = await sig4.fetch(url, {method:'POST', headers:{}, body});
                result = await response.json();
            } else {
                result = await this.localMockSubmit(url, body);
            }
            OperationManager.removeOperation("submitGuests");
        } catch (err) {
            OperationManager.removeOperation("submitGuests");
            //alert(err);
            console.log(`api error: ${err}`);
            throw err;
        }
        return result;
    }

    fetchPrivateContent = async () => {
        const privateURL =  new URL(`${process.env.REACT_APP_PRIVATE_URL}/privateContent.json`);
        let identityToken = await AuthManager.getTokenWithRefresh();
        let headers = {
            'Content-Type': 'application/json',
            'token': identityToken.token
        };
        return fetch(privateURL, {headers})
        .then(response => response.json())
        .then((json) => {
            //console.log(`fetched private data ${JSON.stringify(json)}`);
            return json;
        })
        .catch(err => {
            //console.log(`private fetch failed with error: ${JSON.stringify(err)}`)
        });
    }

    //Private

    // remoteSearch = async (queryParams, headers) => {
    //     const apiName = 'evansdestinyAPI';
    //     const path = '/guests';

    //     const myInit = {
    //         response: false,
    //         headers: headers,
    //         queryStringParameters:
    //             queryParams,
    //     };
    //     console.log(myInit);
    //     const response = await API.get(apiName, path, myInit);
    //     console.log(JSON.stringify(response));
    //     return response;
    // }

    localMockSearch = async (params) => {
        let queryParams, headers;
        let url = new URL('http://localhost:3005/guests');
        Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]))
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers(headers)
          });
        let response = await fetch(request);
        const data = await response.json();
        //console.log(JSON.stringify(data));
        return data;
    }

    localMockSubmit = async (params) => {
        let requestBody, headers;
        //console.log(`local submit for: ${JSON.stringify(requestBody)}, headers: ${headers}`);
        let url = new URL('http://localhost:3005/guests');
        const request = new Request(url, {
            method: 'POST',
            headers: new Headers(headers),
            body: requestBody
          });
        let response = await fetch(request);
        const data = await response.json();
        console.log(JSON.stringify(data));
        return data;
    }

    // remoteSubmit = async (requestBody, headers) => {
    //     const apiName = 'evansdestinyAPI';
    //     const path = '/guests';
    //     const myInit = {
    //         response: false,
    //         headers: headers,
    //         body: requestBody
    //     };

    //     const result = await API.post(apiName, path, myInit);
    //     console.log(result)
    //     return result;
    // }

    // remoteFetchPrivateContent = async () => {
    //     const apiName = 'evansdestinyAPI';
    //     const path = '/private';

    //     const myInit = {
    //         response: false,
    //     };
    //     const response = await API.get(apiName, path, myInit);
    //     console.log(JSON.stringify(response));
    //     return response;
    // }

    // localFetchPrivateContent = async () => {
    //     let url = new URL('http://localhost:3005/private');
    //     let response = await fetch(url);
    //     const data = response.json();
    //     console.log(JSON.stringify(data));
    //     return data;
    // }
    getIDPToken = async (userPoolToken, group_id) => {
        OperationManager.addOperation("getIDPToken");
        let result;
        const gid = group_id.toLowerCase();
        const requestBody = { userPoolToken, gid };
        try {
            if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_USE_REMOTE === "1") {
                result = await this.remoteGetIDPToken(requestBody);
            } else {
                result = await this.localMockGetIDPToken(requestBody);
            }
            OperationManager.removeOperation("getIDPToken");
        } catch (err) {
            OperationManager.removeOperation("getIDPToken");
            console.log(`api error fetching token: ${err}`);
            throw err;
        }
        return result;
    }

    remoteGetIDPToken = async (requestBody) => {
        const apiName = 'evansdestinyAPI';
        const path = '/auth';
        const myInit = {
            body: requestBody
        };
        const result = await API.post(apiName, path, myInit);
        console.log(result)
        return result;
    }

    localMockGetIDPToken = async (requestBody) => {
        let url = new URL('http://localhost:3005/auth');

        const request = new Request(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(requestBody)
          });

        let response = await fetch(request);
        if (!response.ok) {
            throw new Error(response.status);
        }
        const data = await response.json();
        //console.log(JSON.stringify(data));
        return data;
    }

}
const guestAPI = new GuestAPI();
export default guestAPI;