//OperationManager.js

class OperationManager {
    constructor() {
        this.requestInProgressHandler = null;
        this._activeOperationsCount = 0;
        this._activeOperations = {};
    }

    // save operation promise as
    addOperation(opName, opPromise=null) {
        if (opName in this._activeOperations) {
            return false;
        } else {
            this._activeOperationsCount += 1;
            this._activeOperations[opName] = opPromise;
            this.requestInProgressHandler(true);
            return true;
        }
    }

    removeOperation(opName) {
        this._activeOperationsCount -= 1;
        delete this._activeOperations[opName];
        this.requestInProgressHandler(this._activeOperationsCount > 0);
    }

    get activeOperationsCount() {
        return this._activeOperationsCount
    }
}

const opManager = new OperationManager()
export default opManager;